import React from "react";
import { Col, Container, Row } from "react-bootstrap";
import LeftIcon from "../../Assets/images/home/left_icon.png";
import RightIcon from "../../Assets/images/home/right_arrow.png";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { NavLink } from "react-router-dom";
import { baseURL } from "../../Helpers/request";
import axios from "axios";
import { LazyLoadImage } from "react-lazy-load-image-component";
import PropTypes from 'prop-types';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import BrandLogoMobileView from "./BrandLogoMobileView";
import { Skeleton } from "@mui/material";
import { isMobileOnly } from "react-device-detect";


function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}

const BrandLogoSlider = () => {
    const [value, setValue] = React.useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };
  const [userdata, setUserdata] = React.useState([]);
  const [userdatacate, setUserdatacate] = React.useState([]);
  const [userdatacateactive, setUserdatacateactive] = React.useState('');
  const [c_id, setC_id] = React.useState("");
  const [loader, setLoader] = React.useState(false);

  React.useEffect(() => {
    GetUserdata(c_id);
  }, [c_id]);
  React.useEffect(() => {
    GetUserdataCategory();
  }, []);
  const GetUserdata = async(id) => {
    setLoader(true)
    await axios
      .get(`${baseURL}/home/clients/${id}`)
      .then((res) => {
        if (res.data.status == 1) {
          setLoader(false)
          setUserdata(res.data.data);
        } else {
          console.log(res.data.message);
        }
      })
      .catch((error) => {
        if (error) {
          console.log("something went wrong");
        }
      });
  };
  const GetUserdataCategory = () => {
    axios
      .get(`${baseURL}/Client/getClientsCategory`)
      .then((res) => {
        if (res.data.status == 1) {
          setUserdatacate(res.data.data);
        } else {
          console.log(res.data.message);
        }
      })
      .catch((error) => {
        if (error) {
          console.log("something went wrong");
        }
      });
  };

  const settings = {
    className: "center",
    infinite: false,
    autoplay: true,
    Nav: true,
    slidesToShow: 3,
    speed: 500,
    rows: 1,
    slidesPerRow: 1,
    nextArrow: <LazyLoadImage src={RightIcon} alt="Right Icon" />,
    prevArrow: <LazyLoadImage src={LeftIcon} alt="Left Icon" />,
    responsive: [
      {
        breakpoint: 991,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
          rows: 1,
          slidesPerRow: 1,
          infinite: false,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
          rows: 1,
          slidesPerRow: 1,
          infinite: false,
        },
      },
    ],
  };
  return (
    <>
      <section className="bw_brand_slider_section">
        <Container className="bw_custome_container">
          <Row>
            <Col
              xl={12}
              lg={12}
              md={12}
              xs={12}
              className="m-auto"
            >
              <div className="d-flex align-items-center">
                <div className="bw_brand_slider_left_text_main  ">
                  <h2>Some Of Our Clients</h2>
                  <p>
                    Belgium Webnet is a confidant to many global brands. In the
                    last two years, we have served 150+ premium brands. Be it
                    for a small, medium, or large-scale business, we have done
                    it all.
                  </p>
                </div>
              </div>
            </Col>
          </Row>
          {/* Desktop view of client logo Start */}
          <Row className="bwn_client_logo_Desktop_View only_home">
            <Col>
                <div className="bwn_our_clint_tab_section_box">
               
                    {/* <Tabs aria-label="basic tabs example" centered className="bwn_our_clint_tab" indicatorColor="secondary">
                      {userdatacate.map((res, index) => (
                      <Tab label={res.name} key={index} {...a11yProps(index)} style={{borderBottom: userdatacateactive == index ? '2px solid #3D63C3' : '2px solid #0000',color: userdatacateactive == index ? '#3d63c3':'#001100'}} className="bwn_our_clint_tab_button" onClick={(e) => {setC_id(res.id);setUserdatacateactive(index)}}/>))}
                    </Tabs> */}
       
                  <TabPanel value={value} index={0}>
                      <div className="bwn_client_tab_logo">
                           <ul className="bw_client_logo_img_ul only_home pl-0 mb-0">
                      {loader ? <Skeleton height={400}/>:
                       userdata?.slice(0, 10).map((client,ind) => (
                        <li key={ind}>
                          <LazyLoadImage
                            src={client.logo}
                            onClick={() =>
                              window.open(`${client.link}`, "_blank")
                            }
                            alt="Client Logo Image"
                          />
                        </li>
                      ))}
                    </ul>
                      </div>
                  </TabPanel>
                 
                </div>
            </Col>
          </Row>
            {/* Desktop view of client logo end */}
            {/* <div className="bwn_client_slioder_only_home">
                <BrandLogoMobileView userdata={userdata} userdatacate={userdatacate} setC_id={setC_id}  />
            </div> */}
         
          <Row>
            <Col sm={12} md={12} lg={12}>
               <div className="bw_btb_border_black  btn_view_more mt-4 text-center">
                    <NavLink
                      to="/belgiumwebnet-portfolio"
                      className="text-decoration-none bw_custom_btn"
                    >
                      View More
                    </NavLink>
                  </div>
            </Col>
          </Row>
        </Container>
      </section>
    </>
  );
};

export default React.memo(BrandLogoSlider);
